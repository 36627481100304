import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [];

export const dictionary = {
		"/": [~4],
		"/adminnn": [16],
		"/adminnn/birth-detail/[id]/result": [~17],
		"/adminnn/birth-detail/[id]/update": [~18],
		"/adminnn/config/payment/provider": [19],
		"/adminnn/order-item/[id]/destiny-result": [~23],
		"/adminnn/order/ketshopweb-batch": [~21],
		"/adminnn/order/ketshopweb-batch/[batchId]": [~22],
		"/adminnn/order/ketshopweb": [~20],
		"/adminnn/payment": [~24],
		"/adminnn/users": [~25],
		"/adminnn/users/[id]": [~26],
		"/edge": [~27,[2]],
		"/edge/streaming": [~28,[2]],
		"/helper/liff/add-friend": [~29],
		"/helper/liff/close": [30],
		"/helper/order/destiny/guest-login": [~31],
		"/helper/order/destiny/guest-order-link": [~32],
		"/helper/paysolution/payment-page": [33],
		"/helper/redirect/instagram": [34],
		"/node": [~35,[3]],
		"/node/streaming": [~36,[3]],
		"/sentry-example": [37],
		"/[lang]/campaign/line-share/receive": [5],
		"/[lang]/campaign/line-share/send": [6],
		"/[lang]/campaign/reward": [7],
		"/[lang]/landing": [8],
		"/[lang]/orders": [9],
		"/[lang]/orders/bazi/book-reading": [~10],
		"/[lang]/orders/bazi/couple": [~11],
		"/[lang]/orders/guest": [~12],
		"/[lang]/ziamzee/result/temple/[templeId]/ziamzeeNumber/[ziamzeeNumber]": [~13],
		"/[lang]/ziamzee/select-temple": [14],
		"/[lang]/ziamzee/shake": [~15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';